@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper {
  height: 200px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.swiper-3d .swiper-slide-shadow {
  background-color: rgba(255, 255, 255, 0.5);
}

.bottom-nav-item {
  padding: 6px;
}

.swiper-pagination-bullet-active {
  background-color: #43a047;
}